export const users = [
  { name: 'System Administrator', value: 'admin' },
  { name: 'App', value: 'app' },
  // { name: 'Informatore ISF', value: 'isf' },
  // { name: 'Area Manager', value: 'mgr' },
  // { name: 'Interno', value: 'int' },
];

export const poaps = [
  {
    id: 1,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description 1',
    poapRef: 'Poap Ref',
    redeemCode: '',
  },
  {
    id: 2,
    name: 'POAP TITLE',
    img: '',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description 2',
    poapRef: '',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 3,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: '',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: '',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 4,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 5,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '',
  },
  {
    id: 6,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '',
  },
  {
    id: 7,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 8,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 9,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 10,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 11,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 12,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 13,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 14,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 15,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 16,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 17,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 18,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 19,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 20,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 21,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
  {
    id: 22,
    name: 'POAP TITLE',
    img: 'https://picsum.photos/318/180',
    fallbackImage: 'https://picsum.photos/318/180',
    startDate: '2015-12-12',
    endDate: '2016-12-12',
    description: 'poap description',
    poapRef: 'Poap Ref',
    redeemCode: '1234567890abcdefghijklmnopqrstuvwxyz',
  },
];

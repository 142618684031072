// Category Actions
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST'
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED'
export const GET_CATEGORY_SUCCEED = 'GET_CATEGORY_SUCCEED'

export const GET_CATEGORIES_LIST_REQUEST = 'GET_CATEGORIES_LIST_REQUEST'
export const GET_CATEGORIES_LIST_FAILED = 'GET_CATEGORIES_LIST_FAILED'
export const GET_CATEGORIES_LIST_SUCCEED = 'GET_CATEGORIES_LIST_SUCCEED'

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST'
export const ADD_CATEGORY_FAILED = 'ADD_CATEGORY_FAILED'
export const ADD_CATEGORY_SUCCEED = 'ADD_CATEGORY_SUCCEED'

export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST'
export const EDIT_CATEGORY_FAILED = 'EDIT_CATEGORY_FAILED'
export const EDIT_CATEGORY_SUCCEED = 'EDIT_CATEGORY_SUCCEED'

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED'
export const DELETE_CATEGORY_SUCCEED = 'DELETE_CATEGORY_SUCCEED'

// Participant Actions
export const GET_PARTICIPANT_REQUEST = 'GET_PARTICIPANT_REQUEST'
export const GET_PARTICIPANT_FAILED = 'GET_PARTICIPANT_FAILED'
export const GET_PARTICIPANT_SUCCEED = 'GET_PARTICIPANT_SUCCEED'

export const GET_PARTICIPANTS_BY_CATEGORY_REQUEST = 'GET_PARTICIPANTS_BY_CATEGORY_REQUEST'
export const GET_PARTICIPANTS_BY_CATEGORY_FAILED = 'GET_PARTICIPANTS_BY_CATEGORY_FAILED'
export const GET_PARTICIPANTS_BY_CATEGORY_SUCCEED = 'GET_PARTICIPANTS_BY_CATEGORY_SUCCEED'

export const GET_PARTICIPANTS_LIST_REQUEST = 'GET_PARTICIPANTS_LIST_REQUEST'
export const GET_PARTICIPANTS_LIST_FAILED = 'GET_PARTICIPANTS_LIST_FAILED'
export const GET_PARTICIPANTS_LIST_SUCCEED = 'GET_PARTICIPANTS_LIST_SUCCEED'

export const ADD_PARTICIPANT_REQUEST = 'ADD_PARTICIPANT_REQUEST'
export const ADD_PARTICIPANT_FAILED = 'ADD_PARTICIPANT_FAILED'
export const ADD_PARTICIPANT_SUCCEED = 'ADD_PARTICIPANT_SUCCEED'

export const EDIT_PARTICIPANT_REQUEST = 'EDIT_PARTICIPANT_REQUEST'
export const EDIT_PARTICIPANT_FAILED = 'EDIT_PARTICIPANT_FAILED'
export const EDIT_PARTICIPANT_SUCCEED = 'EDIT_PARTICIPANT_SUCCEED'

export const DELETE_PARTICIPANT_REQUEST = 'DELETE_PARTICIPANT_REQUEST'
export const DELETE_PARTICIPANT_FAILED = 'DELETE_PARTICIPANT_FAILED'
export const DELETE_PARTICIPANT_SUCCEED = 'DELETE_PARTICIPANT_SUCCEED'

// User Actions
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCEED = 'USER_LOGIN_SUCCEED'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED'
export const USER_LOGOUT_SUCCEED = 'USER_LOGOUT_SUCCEED'

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST'
export const USER_FORGOT_PASSWORD_FAILED = 'USER_FORGOT_PASSWORD_FAILED'
export const USER_FORGOT_PASSWORD_SUCCEED = 'USER_FORGOT_PASSWORD_SUCCEED'

export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST'
export const GET_USERS_LIST_FAILED = 'GET_USERS_LIST_FAILED'
export const GET_USERS_LIST_SUCCEED = 'GET_USERS_LIST_SUCCEED'

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_FAILED = 'ADD_USER_FAILED'
export const ADD_USER_SUCCEED = 'ADD_USER_SUCCEED'

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED'
export const EDIT_USER_SUCCEED = 'EDIT_USER_SUCCEED'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'
export const DELETE_USER_SUCCEED = 'DELETE_USER_SUCCEED'

// Vote Actions
export const GET_VOTES_LIST_REQUEST = 'GET_VOTES_LIST_REQUEST'
export const GET_VOTES_LIST_FAILED = 'GET_VOTES_LIST_FAILED'
export const GET_VOTES_LIST_SUCCEED = 'GET_VOTES_LIST_SUCCEED'

export const ADD_VOTE_REQUEST = 'ADD_VOTE_REQUEST'
export const ADD_VOTE_FAILED = 'ADD_VOTE_FAILED'
export const ADD_VOTE_SUCCEED = 'ADD_VOTE_SUCCEED'

/* Common Actions*/
export const RESET_LOADING = 'RESET_LOADING'

/* Redemptions Actions*/
export const GET_REDEMPTIONS_LIST_REQUEST = 'GET_REDEMPTIONS_LIST_REQUEST'
export const GET_REDEMPTIONS_LIST_FAILED = 'GET_REDEMPTIONS_LIST_FAILED'
export const GET_REDEMPTIONS_LIST_SUCCEED = 'GET_REDEMPTIONS_LIST_SUCCEED'

/* Poaps Actions*/
export const GET_POAPS_LIST_REQUEST = 'GET_POAPS_LIST_REQUEST'
export const GET_POAPS_LIST_FAILED = 'GET_POAPS_LIST_FAILED'
export const GET_POAPS_LIST_SUCCEED = 'GET_POAPS_LIST_SUCCEED'

export const ADD_POAP_REQUEST = 'ADD_POAP_REQUEST'
export const ADD_POAP_FAILED = 'ADD_POAP_FAILED'
export const ADD_POAP_SUCCEED = 'ADD_POAP_SUCCEED'

export const EDIT_POAP_REQUEST = 'EDIT_POAP_REQUEST'
export const EDIT_POAP_FAILED = 'EDIT_POAP_FAILED'
export const EDIT_POAP_SUCCEED = 'EDIT_POAP_SUCCEED'

export const DELETE_POAP_REQUEST = 'DELETE_POAP_REQUEST'
export const DELETE_POAP_FAILED = 'DELETE_POAP_FAILED'
export const DELETE_POAP_SUCCEED = 'DELETE_POAP_SUCCEED'

/* Products Actions*/
export const GET_PRODUCTS_LIST_REQUEST = 'GET_PRODUCTS_LIST_REQUEST'
export const GET_PRODUCTS_LIST_FAILED = 'GET_PRODUCTS_LIST_FAILED'
export const GET_PRODUCTS_LIST_SUCCEED = 'GET_PRODUCTS_LIST_SUCCEED'

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST'
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED'
export const ADD_PRODUCT_SUCCEED = 'ADD_PRODUCT_SUCCEED'

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST'
export const EDIT_PRODUCT_FAILED = 'EDIT_PRODUCT_FAILED'
export const EDIT_PRODUCT_SUCCEED = 'EDIT_PRODUCT_SUCCEED'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED'
export const DELETE_PRODUCT_SUCCEED = 'DELETE_PRODUCT_SUCCEED'

/* Specialities Actions*/
export const GET_SPECIALITIES_LIST_REQUEST = 'GET_SPECIALITIES_LIST_REQUEST'
export const GET_SPECIALITIES_LIST_FAILED = 'GET_SPECIALITIES_LIST_FAILED'
export const GET_SPECIALITIES_LIST_SUCCEED = 'GET_SPECIALITIES_LIST_SUCCEED'

export const ADD_SPECIALITY_REQUEST = 'ADD_SPECIALITY_REQUEST'
export const ADD_SPECIALITY_FAILED = 'ADD_SPECIALITY_FAILED'
export const ADD_SPECIALITY_SUCCEED = 'ADD_SPECIALITY_SUCCEED'

export const EDIT_SPECIALITY_REQUEST = 'EDIT_SPECIALITY_REQUEST'
export const EDIT_SPECIALITY_FAILED = 'EDIT_SPECIALITY_FAILED'
export const EDIT_SPECIALITY_SUCCEED = 'EDIT_SPECIALITY_SUCCEED'

export const DELETE_SPECIALITY_REQUEST = 'DELETE_SPECIALITY_REQUEST'
export const DELETE_SPECIALITY_FAILED = 'DELETE_SPECIALITY_FAILED'
export const DELETE_SPECIALITY_SUCCEED = 'DELETE_SPECIALITY_SUCCEED'

/* Documents Actions*/
export const GET_DOCUMENTS_LIST_REQUEST = 'GET_DOCUMENTS_LIST_REQUEST'
export const GET_DOCUMENTS_LIST_FAILED = 'GET_DOCUMENTS_LIST_FAILED'
export const GET_DOCUMENTS_LIST_SUCCEED = 'GET_DOCUMENTS_LIST_SUCCEED'

export const GET_DOCUMENT_DETAIL_REQUEST = 'GET_DOCUMENT_DETAIL_REQUEST'
export const GET_DOCUMENT_DETAIL_FAILED = 'GET_DOCUMENT_DETAIL_FAILED'
export const GET_DOCUMENT_DETAIL_SUCCEED = 'GET_DOCUMENT_DETAIL_SUCCEED'

export const ADD_DOCUMENT_REQUEST = 'ADD_DOCUMENT_REQUEST'
export const ADD_DOCUMENT_FAILED = 'ADD_DOCUMENT_FAILED'
export const ADD_DOCUMENT_SUCCEED = 'ADD_DOCUMENT_SUCCEED'

export const EDIT_DOCUMENT_REQUEST = 'EDIT_DOCUMENT_REQUEST'
export const EDIT_DOCUMENT_FAILED = 'EDIT_DOCUMENT_FAILED'
export const EDIT_DOCUMENT_SUCCEED = 'EDIT_DOCUMENT_SUCCEED'

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST'
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED'
export const DELETE_DOCUMENT_SUCCEED = 'DELETE_DOCUMENT_SUCCEED'

export const GET_DOCUMENT_PRODUCT_LIST_REQUEST =
  'GET_DOCUMENT_PRODUCT_LIST_REQUEST'
export const GET_DOCUMENT_PRODUCT_LIST_FAILED =
  'GET_DOCUMENT_PRODUCT_LIST_FAILED'
export const GET_DOCUMENT_PRODUCT_LIST_SUCCEED =
  'GET_DOCUMENT_PRODUCT_LIST_SUCCEED'

export const ADD_PRODUCT_DOCUMENT_REQUEST = 'ADD_PRODUCT_DOCUMENT_REQUEST'
export const ADD_PRODUCT_DOCUMENT_FAILED = 'ADD_PRODUCT_DOCUMENT_FAILED'
export const ADD_PRODUCT_DOCUMENT_SUCCEED = 'ADD_PRODUCT_DOCUMENT_SUCCEED'

export const EDIT_PRODUCT_DOCUMENT_REQUEST = 'EDIT_PRODUCT_DOCUMENT_REQUEST'
export const EDIT_PRODUCT_DOCUMENT_FAILED = 'EDIT_PRODUCT_DOCUMENT_FAILED'
export const EDIT_PRODUCT_DOCUMENT_SUCCEED = 'EDIT_PRODUCT_DOCUMENT_SUCCEED'

export const DELETE_PRODUCT_DOCUMENT_REQUEST = 'DELETE_PRODUCT_DOCUMENT_REQUEST'
export const DELETE_PRODUCT_DOCUMENT_FAILED = 'DELETE_PRODUCT_DOCUMENT_FAILED'
export const DELETE_PRODUCT_DOCUMENT_SUCCEED = 'DELETE_PRODUCT_DOCUMENT_SUCCEED'

export const GET_DOCUMENT_SPECIALITY_LIST_REQUEST =
  'GET_DOCUMENT_SPECIALITY_LIST_REQUEST'
export const GET_DOCUMENT_SPECIALITY_LIST_FAILED =
  'GET_DOCUMENT_SPECIALITY_LIST_FAILED'
export const GET_DOCUMENT_SPECIALITY_LIST_SUCCEED =
  'GET_DOCUMENT_SPECIALITY_LIST_SUCCEED'

export const ADD_DOCUMENT_SPECIALITY_REQUEST = 'ADD_DOCUMENT_SPECIALITY_REQUEST'
export const ADD_DOCUMENT_SPECIALITY_FAILED = 'ADD_DOCUMENT_SPECIALITY_FAILED'
export const ADD_DOCUMENT_SPECIALITY_SUCCEED = 'ADD_DOCUMENT_SPECIALITY_SUCCEED'

export const EDIT_DOCUMENT_SPECIALITY_REQUEST =
  'EDIT_DOCUMENT_SPECIALITY_REQUEST'
export const EDIT_DOCUMENT_SPECIALITY_FAILED = 'EDIT_DOCUMENT_SPECIALITY_FAILED'
export const EDIT_DOCUMENT_SPECIALITY_SUCCEED =
  'EDIT_DOCUMENT_SPECIALITY_SUCCEED'

export const DELETE_DOCUMENT_SPECIALITY_REQUEST =
  'DELETE_DOCUMENT_SPECIALITY_REQUEST'
export const DELETE_DOCUMENT_SPECIALITY_FAILED =
  'DELETE_DOCUMENT_SPECIALITY_FAILED'
export const DELETE_DOCUMENT_SPECIALITY_SUCCEED =
  'DELETE_DOCUMENT_SPECIALITY_SUCCEED'

export const CHANGE_DOCUMENT_SPECIALITY_LIST_REQUEST =
  'CHANGE_DOCUMENT_SPECIALITY_LIST_REQUEST'
export const CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED =
  'CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED'
export const CHANGE_DOCUMENT_SPECIALITY_LIST_SUCCEED =
  'CHANGE_DOCUMENT_SPECIALITY_LIST_SUCCEED'

export const GET_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST =
  'GET_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST'
export const GET_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED =
  'GET_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED'
export const GET_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED =
  'GET_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED'

export const ADD_DOCUMENT_ADDITIONAL_LINK_REQUEST =
  'ADD_DOCUMENT_ADDITIONAL_LINK_REQUEST'
export const ADD_DOCUMENT_ADDITIONAL_LINK_FAILED =
  'ADD_DOCUMENT_ADDITIONAL_LINK_FAILED'
export const ADD_DOCUMENT_ADDITIONAL_LINK_SUCCEED =
  'ADD_DOCUMENT_ADDITIONAL_LINK_SUCCEED'

export const EDIT_DOCUMENT_ADDITIONAL_LINK_REQUEST =
  'EDIT_DOCUMENT_ADDITIONAL_LINK_REQUEST'
export const EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED =
  'EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED'
export const EDIT_DOCUMENT_ADDITIONAL_LINK_SUCCEED =
  'EDIT_DOCUMENT_ADDITIONAL_LINK_SUCCEED'

export const DELETE_DOCUMENT_ADDITIONAL_LINK_REQUEST =
  'DELETE_DOCUMENT_ADDITIONAL_LINK_REQUEST'
export const DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED =
  'DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED'
export const DELETE_DOCUMENT_ADDITIONAL_LINK_SUCCEED =
  'DELETE_DOCUMENT_ADDITIONAL_LINK_SUCCEED'

export const CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST =
  'CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST'
export const CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED =
  'CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED'
export const CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED =
  'CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED'
